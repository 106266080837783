<template lang="pug">
#map
InfoWindow(ref='infoWin')
</template>

<script>

import { Loader } from '@googlemaps/js-api-loader';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import Styles from './Styles'
import InfoWindow from './InfoWindow'

export default {
	data(){
		return {
			map:Object,
			markers: [],
			bounds:Object,
		}
	},
	components:{ InfoWindow },
	methods:{
		marker(visitor){
			let position = {lat:visitor.ip.lat, lng:visitor.ip.lng};
			let mrkr = new google.maps.Marker({
				map: this.map,
				data: visitor,
				icon: this.markerPast,
			//	zIndex: zIndex,
				position: position,
				draggable: false
			});
			mrkr.addListener('click', () => {
				this.$refs.infoWin.show(mrkr);
			});
			this.markers.push(mrkr);
			this.bounds.extend(position);
		},
		init() {
			const loader = new Loader({
				apiKey: "AIzaSyAr-ausL4ETdSB_QBNN275EBaGtebYpNsc",
				version: "weekly",
			//	https://www.npmjs.com/package/google-maps
				// libraries: ["places", "drawing"]
			});
			loader.load().then(() => {
				this.map = new google.maps.Map(document.getElementById('map'), {
					styles: Styles,
					//maxZoom : 20,
					clickableIcons: false,
					disableDefaultUI: true,
					gestureHandling: 'greedy'
				});
				this.markerLive = {
					url : '/img/markers/live.svg',
					scaledSize : new google.maps.Size(32, 44)
				}
				this.markerPast = {
					url : '/img/markers/past.svg',
					scaledSize : new google.maps.Size(32, 44)
				}
				this.$refs.infoWin.init(this.map);
				this.bounds = new google.maps.LatLngBounds();
				this.map.setZoom(13);
			}).catch(e => {
				console.log('error loading gmaps:', e);
			});
		},
		setPastVisitors(visitors){
			if (visitors.length == 1){
				let visitor = visitors[0].ip;
				this.marker(visitor);
				this.map.setOptions({minZoom: 4});
				this.map.setCenter({lat:visitor.ip.lat, lng:visitor.ip.lng});
			}	else{
				visitors.forEach(visitor => this.marker(visitor));
				this.map.fitBounds(this.bounds);
			}
		//	const markerCluster = new MarkerClusterer(this.map, this.markers, {imagePath: '/img/markers/m	'});
		}
	},
};
</script>

<style lang="stylus" scoped>
#map
	width 100%
	height 300px
	background #eee
</style>
<template lang="pug">
#iw.gmap_info_window
	img.iw_close(src='/img/gfx/close_x_dk.svg' @click='hide')
	.content
		.location {{location(ip)}}
		.ip {{ip.address}}
		.sessions Sessions: {{sessions}}
		.lastseen Last Seen: {{lastseen}}
</template>

<script>
import Utils from '../mixins/Utils';

export default{

	data(){
		return{
			gmap: Object,
			gmiw: Object,
			ip: Object,
			sessions: Number,
			lastseen: String,
			html: HTMLDivElement
		}
	},
	mixins: [Utils],
	mounted(){
		this.html = document.getElementById('iw');
	},
	methods:{
		show(mrkr){
		//	console.log('mrkr.data', mrkr.data);
			this.ip = mrkr.data.ip;
			this.lastseen = this.timeAgo(mrkr.data.sessions[0].date);
			this.sessions = mrkr.data.sessions.length;
			this.html.style.display = 'block';
			this.gmiw.setContent(this.html);
			this.gmiw.open(this.gmap, mrkr);
		},
		hide(){
			this.gmiw.close();
		},
		init(gmap)
		{
			this.gmap = gmap;
			this.gmap.addListener('click', this.hide);
			this.gmiw = new google.maps.InfoWindow();
			// customize the info window. must be called everytime it opens //
			google.maps.event.addListener(this.gmiw, "domready", function(){
			// hide right side scroll bar //
				document.querySelector('.gm-style-iw-d').style.overflow = 'hidden';
			// hide default close button //
				document.querySelector('.gm-ui-hover-effect').style.display = 'none';
			// main infowindow container //
				let window = document.querySelector('.gm-style-iw');
				window.style.padding = 0;
				window.style.marginRight = 20;
				window.style.paddingRight = 14; // fix for weird behavior in firefox //
			});
		}
	}
}

</script>

<style lang="stylus" scoped>

.iw_close
	top -5px
	right -6px
	width 40px
	height 40px
	padding 14px
	cursor pointer
	position absolute

$m = 10px
.gmap_info_window
	display none
	overflow hidden
	font-weight 400
	text-align left
	.content
	//	background #eee
		margin $m 28px $m $m
	.location
		font-size .9rem
		margin-bottom 5px
	.ip
		color red
	.ip, .lastseen, .sessions
		font-size .7rem
		margin-bottom 4px

</style>